import React from 'react'
import Header from '../Comp/Header/Header'
import Footer from '../Comp/Footer/Footer'

const Agreement  = () => {
  return (
    <>

    <div>
        <Header/>
    </div>
       <div className='container' style={{paddingTop:'120px'}}>
        <div className='row'>
            <div className='col-lg-9 m-auto'>

                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                        

                        <p className='text-white'>

                        <h4 className=' text-center my-4'  style={{fontWeight:'600'}}><span className='Privacy'> Agreement for Sale of Tokens</span></h4>
                        This Agreement for Sale of Tokens is entered into between ArmorAi, a Securities and Exchange Commission (SEC) corporation, with its principal place of business at Armor Ai (referred to as "Seller"), and the individual or entity purchasing the tokens (referred to as "Buyer").<br/><br/>

                        Background
                        Seller is engaged in the development and distribution of cryptographic tokens (referred to as "Tokens") as part of its cryptocurrency project. Buyer desires to purchase a certain number of Tokens from Seller, subject to the terms and conditions set forth in this Agreement.<br/><br/>

                        <h5>  Terms and Conditions</h5>
                        Tokens: Seller agrees to sell, and Buyer agrees to purchase, the specified number of Tokens as detailed in the Purchase Order, at the agreed-upon purchase price per Token.<br/><br/>

                        <h5>Purchase Price</h5> The purchase price per Token is set forth in the Purchase Order and shall be paid by Buyer to Seller in the agreed currency or cryptocurrency as specified.<br/><br/>

                        <h5>Payment</h5> 
                        Buyer shall make the payment in full within the time frame specified in the Purchase Order. Failure to make timely payment may result in cancellation of the order.<br/><br/>

                       <h5> Delivery</h5>
                        Seller shall deliver the purchased Tokens to Buyer within a reasonable time frame following the receipt of payment, subject to any additional terms and conditions specified in the Purchase Order.<br/><br/>

                       <h5> Representations and Warranties</h5>
                        a. Seller represents and warrants that it has the authority to sell the Tokens and that the Tokens are not subject to any encumbrances or claims by third parties.<br/> 
                        b. Buyer represents and warrants that it has conducted its own due diligence regarding the Tokens and understands the risks associated with cryptocurrency investments.<br/><br/>

                       <h5> Risk of Loss</h5>
                        The risk of loss or damage to the Tokens shall pass to the Buyer upon delivery. Seller shall not be responsible for any loss or damage incurred after delivery.<br/><br/>

                        <h5>Governing Law and Jurisdiction</h5> This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of the Securities and Exchange Commission (SEC).<br/><br/>

                         <h5> Entire Agreement</h5>
                         This Agreement constitutes the entire agreement between the parties concerning the purchase and sale of the Tokens and supersedes any prior agreements or understandings, whether written or oral.<br/><br/>

                       <h4 className='text-center my-3'>  Miscellaneous </h4>
                        Assignment: Buyer shall not assign or transfer any rights or obligations under this Agreement without the prior written consent of Seller.<br/><br/>

                        Severability: If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the validity, legality, or enforceability of the remaining provisions shall not be affected or impaired.<br/><br/>

                        <h5> Amendment</h5> 
                        This Agreement may only be amended in writing and signed by both parties.
                        </p>
                    </div>
                </div>
            </div>
        </div>
       </div>



       <div className='mt-4'>
        <Footer/>
       </div>
      </>
  )
}

export default Agreement 