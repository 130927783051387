import React from 'react'
import Header from '../Comp/Header/Header'
import Footer from '../Comp/Footer/Footer'

const Guide = () => {
  return (
    <>

    <div>
        <Header/>
    </div>
       <div className='container' style={{paddingTop:'80px'}}>
        <div className='row'>
            <div className='col-lg-9 m-auto'>
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>

                          
                          <p className='text-white'>


                         <h4 className='text-center my-4' style={{}}> <span className='Privacy   '>  Armor Ai Brand Guide </span></h4>
                        Welcome to the Armor Ai Brand Guide! This guide provides an overview of our brand identity, including our mission, visual elements, and guidelines for using our brand assets. By following these guidelines, you'll ensure consistency and maintain the integrity of the Armor Ai brand.<br/><br/>

                        <h5> Our Mission</h5>
                        At Armor Ai, our mission is to empower businesses with cutting-edge artificial intelligence solutions. We strive to create innovative products that streamline processes, enhance decision-making, and drive growth for our clients. Our brand reflects our commitment to excellence, reliability, and forward-thinking solutions.<br/><br/>

                        <h5> Brand Identity</h5>
                        Logo
                        The Armor Ai logo is a visual representation of our brand. It consists of a bold, modern, and dynamic wordmark accompanied by a distinctive icon. Please use the logo exactly as provided, without altering its proportions, colors, or any other elements.<br/><br/>

                        <h5>Primary Logo: Armor Ai Logo</h5>
                        Icon Only: Armor Ai Icon
                        Color Palette
                        Our brand colors are carefully selected to convey our core values and evoke a sense of trust, professionalism, and innovation. The primary brand colors are:
                        <br/><br/>
                        Primary Blue: #005EFF<br/>
                        Secondary Blue: #007BFF<br/>
                        Gray: #333333<br/>
                        White: #FFFFFF<br/>
                        Please use these colors consistently across all branded materials, both online and offline.<br/><br/>

                        <h5>Typography</h5>
                        Our brand typography is clean, modern, and easily readable. The primary font for headings and titles is Montserrat, while the primary font for body text is Roboto. These fonts should be used consistently across all brand communications.
                        <br/><br/>
                        Headings & Titles: Montserrat<br/>
                        Body Text: Roboto<br/>
                        Imagery<br/>
                        When selecting imagery for our brand, strive for visuals that reflect innovation, technology, and a sense of partnership. High-quality photographs or illustrations that align with our brand values can be used in various marketing materials, website banners, and social media posts.<br/><br/>

                        <h5> Brand Voice</h5><br/>
                        Our brand voice is professional, authoritative, and approachable. We aim to communicate complex concepts in a clear and concise manner, without compromising on technical accuracy. We value transparency, trust, and a customer-centric approach in all our communications.<br/>
                          </p>

                    </div>
                </div>
            </div>
        </div>
       </div>
    

    <div className='mt-4'>
        <Footer/>
    </div>
     </>
  )
}

export default Guide