import React, { useEffect, useState } from "react";
import Head from "../img/title 2.png";
import Mdl from "../img/unn.png";
import Mdl2 from "../img/Wall.png";
import { FaWallet } from "react-icons/fa";
import "./Stacking.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ethers } from "ethers";
// Import just a few select items
import { BrowserProvider, parseUnits, SigningManager } from "ethers";
// Import from a specific export
import { HDNodeWallet } from "ethers/wallet";

const Staking = () => {
  const [req_contract, setReqContract] = useState("");
  const [isInstalled, setIsInstalled] = useState(true);

  const interactWithContract = async () => {
    // Frontend Integration Script with MetaMask

    // Check if MetaMask is installed
    function checkMetaMask() {
      if (typeof window.ethereum !== "undefined") {
        console.log("MetaMask is installed!");
        return true;
      } else {
        console.log("MetaMask is not installed.");
        return false;
      }
    }

    // Request access to the user's account
    async function requestAccountAccess() {
      try {
        // Request access to the user's MetaMask accounts
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        // Handle the accounts array (e.g., select the first account)
        const selectedAccount = accounts[0];

        // Continue with your application logic here...
        console.log(
          "Connected to MetaMask. Selected account:",
          selectedAccount
        );

        // Call other functions or interact with the smart contract
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    }

    // Event listener for the "Connect" button
    const connectButton = document.getElementById("connectButton");
    connectButton.addEventListener("click", async () => {
      // Check if MetaMask is installed
      if (checkMetaMask()) {
        // Request access to the user's account
        await requestAccountAccess();
      } else {
        // Display a message or take appropriate action if MetaMask is not installed
        console.log("Please install MetaMask to connect to the dapp.");
      }
    });
  };

  const data = [
    {
      FRED: "FRED",
      will: "Will Be Locked",
      day: "1 Days",
      key: "Current APY:",
      percentage: "300 %",
      fredred2: "Fred",
      earn: "Earn:",
      fred: "FRED",
      Staked: "FRED Staked",
      In: "In progress",
      connect: "Connect Wallet",
      You: "You Staked:",
      Zero: "0 FRED",
      Reward: "Your Reward:",
      Zero1: "0 FRED",
      Total: "Total Staked In Pool:",
      Zero3: "0 FRED",
    },
    {
      FRED: "FRED",
      will: "Will Be Locked",
      day: "1 Days",
      key: "Current APY:",
      percentage: "300 %",
      fredred2: "Fred",
      earn: "Earn:",
      fred: "FRED",
      Staked: "FRED Staked",
      In: "In progress",
      connect: "Connect Wallet",
      You: "You Staked:",
      Zero: "0 FRED",
      Reward: "Your Reward:",
      Zero1: "0 FRED",
      Total: "Total Staked In Pool:",
      Zero3: "0 FRED",
    },
    {
      FRED: "FRED",
      will: "Will Be Locked",
      day: "1 Days",
      key: "Current APY:",
      percentage: "300 %",
      fredred2: "Fred",
      earn: "Earn:",
      fred: "FRED",
      Staked: "FRED Staked",
      In: "In progress",
      connect: "Connect Wallet",
      You: "You Staked:",
      Zero: "0 FRED",
      Reward: "Your Reward:",
      Zero1: "0 FRED",
      Total: "Total Staked In Pool:",
      Zero3: "0 FRED",
    },
  ];

  useEffect(() => {
    // interactWithContract();
  }, []);

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 mt-5">
            <div>
              <h2 className="text-white  text-center">Stacking </h2>
            </div>
            <div className="row">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  onClick={() => interactWithContract()}
                  className="Wallet mt-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <FaWallet className="me-2" /> Connect Wallet
                </button>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 m-auto">
                <div className="d-flex justify-content-center">
                  <img src={Head} alt="#" style={{ width: "40%" }} />
                </div>

                <div>
                  <button className="FRED">FRED Staking</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 m-auto">
            <div className="row">
              {data.map((res, key) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
                    <div
                      className="card KD1"
                      style={{
                        backgroundColor: "transparent",
                        border: "2px dotted #ccc",
                      }}
                      key={key}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-7">
                            <img
                              src={Head}
                              alt="#"
                              className="hed"
                              style={{ width: "50%" }}
                            />
                          </div>

                          <div className="col-5 text-white">
                            <h5>{res.FRED} </h5>
                            <p style={{ fontSize: "15px" }}>{res.will}</p>
                            <button className="day">{res.day}</button>
                          </div>
                        </div>

                        <div
                          className="mt-4 "
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.key}</h6>
                          <p>{res.percentage}</p>
                        </div>

                        <div
                          className="mt-1 "
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.earn}</h6>
                          <p>{res.fredred2} </p>
                        </div>

                        <div
                          className="mt-1 "
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.Staked}</h6>
                          <p>{res.In} </p>
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                          <button className="connet">{res.connect}</button>
                        </div>
                        <hr style={{ color: "#fff", opacity: "0.7" }} />

                        <div
                          className="mt-4"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.You}</h6>
                          <p>{res.Zero}</p>
                        </div>

                        <div
                          className="mt-1"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.Reward}</h6>
                          <p>{res.Zero1}</p>
                        </div>

                        <div
                          className="mt-1"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#b9b9b9",
                          }}
                        >
                          <h6>{res.Total}</h6>
                          <p style={{ fontWeight: "600", color: "#fff" }}>
                            {res.Zero3}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ backdropFilter: "blur(5px)" }}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-0" style={{ backgroundColor: "#fff" }}>
            <div class="modal-header border-0 ">
              <h6>Connect Wallet</h6>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {isInstalled ? (
                <>
                  <div className="WalletConnect1 py-3">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={Mdl}
                        alt="#"
                        style={{ width: "70px", border: "1px solid gray" }}
                      />
                    </div>
                    <h6 className="  text-center mt-2">Metamask</h6>
                    <p className="text-center">Connect With Metamask</p>
                  </div>

                  {/* second cont start */}

                  <div className="WalletConnect py-3">
                    <div
                      className=" "
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={Mdl2}
                        alt="#"
                        style={{ width: "70px", border: "1px solid gray" }}
                      />
                    </div>
                    <h6 className="mt-3 text-center">WalletConnect</h6>
                    <p className="text-center">
                      Scan with WalletConnect to connect
                    </p>
                  </div>
                </>
              ) : (
                <div>Not Connected</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Staking;
