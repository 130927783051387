import React from 'react'
import Header from '../Header/Header'
import musk from '../../Comp/img/Musk.png'
import bill from '../../Comp/img/bill (2).png'
import Nick from '../../Comp/img/nick.png'
import mar from '../../Comp/img/Marcus.png'
import jam from '../../Comp/img/Jam.png'
import lan from '../../Comp/img/Lan.png'
import dow from '../../Comp/img/dow.png'
import hec from '../../Comp/img/hec.png'
import abd from '../../Comp/img/vp.png'
import {BsTwitter} from 'react-icons/bs'
import {AiFillLinkedin} from 'react-icons/ai'
import {TbNetwork} from 'react-icons/tb'
import {BsWikipedia} from 'react-icons/bs'
import Footer from '../Footer/Footer'
import { NavLink, useLocation } from 'react-router-dom'




export const SportLight = () => {


    const Location = useLocation();

  return ( 

    <>
    <div>
    <Header/>
    </div>

     <div className='container' style={{paddingTop:'140px'}}>
      <div className='row'>
       
      <div className='col-lg-10 m-auto'>
      <div className='row'>
      
      <div className='col-lg-6 col-md-6 col-sm-12 my-4 kl1 order-last order-md-last  '>
        
       <div class="card" style={{backgroundColor:'transparent', color:'#fff'}}> 
      <div class="card-body">
       <h3 class="mb-3  ">  <span className='Nick'>  Elon Musk -  </span>     </h3>
       <p class="card-text military mb-4" style={{fontSize:'18px'}}> AI is more dangerous than, say, mismanaged aircraft design or production maintenance or bad car production, in the sense that it is, it has the potential — however small one may regard that probability, but it is non-trivial — it has the potential of civilization destruction.</p>

       <div>
       <NavLink to='https://twitter.com/elonmusk'  style={{color:'#fff'}}>
       <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
   </NavLink>

   <NavLink to='https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A74721535&keywords=elon%20musk&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=c3d4fde5-0ca6-438f-852a-d064501c583c&sid=TUY'  style={{color:'#fff'}}>
   <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
</NavLink>
       </div>
       
   </div>
       </div>
       </div>


       <div className='col-lg-1  '> 
       </div>


       <div className='col-lg-5 col-md-6 col-sm-12 mt-0  order-first order-md-last'>
           <img src={musk} alt='musk'   style={{width:'100%'}}/>
       </div>

      </div> 




      <div className='row mt-5'>  

       <div className='col-lg-6 col-md-6 col-sm-12 mt-5 '>
           <img src={bill} alt='musk' className='p-4 Billgets'  style={{width:'100%'}}/>
       </div>

       <div className='col-lg-6 col-md-6 col-sm-12 mt-5   kl1' >
        
       <div class="card kl" style={{backgroundColor:'transparent', color:'#fff', paddingTop:'90px'}}> 
      <div class="card-body">
       <h3 class="mb-3  "> <span className='Nick'>   Bill Gates - </span>      </h3>
       <p class="card-text military mb-4" style={{fontSize:'18px'}}> First the machines will do a lot of jobs for us and not be super intelligent. That should be positive if we manage it well. A few decades after that though the intelligence is strong enough to be a concern
       </p>

       <div>
       <NavLink to='https://twitter.com/BillGates'  style={{color:'#fff'}}>
       <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
   </NavLink>

   <NavLink to='https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAA8BYqEBCGLg_vT_ca6mMEqkpp9nVffJ3hc&keywords=bill%20gates&origin=RICH_QUERY_SUGGESTION&position=0&searchId=5b87d758-290d-4619-9b4b-ce46f255c091&sid=sE!'  style={{color:'#fff'}}>
   <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
</NavLink>
       </div>
       
   </div>
       </div>
       </div>

      </div> 


           
      <div className='row mt-5 pt-5 '  >
      
      <div className='col-lg-6 col-md-6 col-sm-12 my-4   order-last order-md-first  '>
        
       <div class="card  kl" style={{backgroundColor:'transparent', color:'#fff',paddingTop:'20px'}}> 
      <div class="card-body">
       <h3 class="mb-3 "> <span className='Nick'> Nick Bostrom - </span>  </h3>
       <p class="card-text military mb-4" style={{fontSize:'18px'}}> If an advanced AI's instrumental goals conflict with humanity's goals, the AI might harm humanity in order to acquire more resources or prevent itself from being shut down, but only as a way to achieve its ultimate goal.</p>
       
       <div>
       <NavLink to='https://twitter.com/DebateThoughts '  style={{color:'#fff'}}>
       <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
   </NavLink>

{/* 
   <NavLink to=''  style={{color:'#fff'}}>
   <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
</NavLink> */}
       </div>
       
   </div>
       </div>
       </div>

 


       <div className='col-lg-6 col-md-6 col-sm-12 mt-0   order-first order-md-last   '>
           <img src={Nick} alt='musk'   style={{width:'100%'}}/>
       </div>

      </div> 




      <div className='row mt-5 stt1'> 

     

      <div className='col-lg-5 col-md-6 col-sm-12 mt-5     '>
          <img src={mar} alt='musk' className='p-4'  style={{width:'100%'}}/>
      </div>
      <div className='col-lg-1      '> 
  </div>

      <div className='col-lg-6 col-md-6 col-sm-12 mt-5  kl1 ' >
       
      <div class="card kl" style={{backgroundColor:'transparent', color:'#fff', paddingTop:'90px'}}> 
     <div class="card-body">
      <h3 class="mb-3 "> <span className='Nick'> Gary Marcus - </span></h3>
      <p class="card-text military mb-4" style={{fontSize:'18px'}}>Once computers can effectively reprogram themselves, and successively improve themselves, leading to a so-called technological singularity or intelligence explosion the risks of machines outwitting humans in battles for resources and self-preservation cannot simply be dismissed
      </p>

        
      <div>
      <NavLink to='https://twitter.com/GaryMarcus'  style={{color:'#fff'}}>
      <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
  </NavLink>
       
      <NavLink to='https://www.linkedin.com/search/results/all/?keywords=Gary%20Marcus%20&origin=GLOBAL_SEARCH_HEADER&sid=8wX'  style={{color:'#fff'}}>
      <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
  </NavLink>
      </div>
      
  </div>
      </div>
      </div>

     </div> 



     <div className='row mt-5 pt-5  stt1'  >
      
     <div className='col-lg-6 col-md-6 col-sm-12 my-4 order-last order-md-last  '>
       
      <div class="card  kl" style={{backgroundColor:'transparent', color:'#fff',paddingTop:'80px'}}> 
     <div class="card-body">
      <h3 class="mb-3 "> <span className='Nick'> James Barrat- </span></h3>
      <p class="card-text military mb-4" style={{fontSize:'18px'}}> I don’t want to really scare you, but it was alarming how many people I talked to who are highly placed people in AI who have retreats that are sort of ‘bug out’ houses, to which they could flee if it all hits the fan.</p>

      <div>
      <NavLink to='https://twitter.com/jrbarrat'  style={{color:'#fff'}}>
      <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
  </NavLink>


  <NavLink to='https://www.linkedin.com/in/james-barrat-01a30a56/'  style={{color:'#fff'}}>
  <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
</NavLink>
      </div>
      
  </div>
      </div>
      </div>




      <div className='col-lg-6 col-md-6 col-sm-12 mt-0 order-first order-md-last'>
          <img src={jam} alt='jam'   style={{width:'100%'}}/>
      </div>

     </div> 



     <div className='row mt-5 stt1'>  

     <div className='col-lg-6 col-md-6 col-sm-12 mt-5  kl3   ' style={{paddingTop:'110px'}}>
         <img src={dow} alt='musk'  style={{width:'100%'}}/>
     </div> 

     <div className='col-lg-6 col-md-6 col-sm-12 mt-5   kl1 ' >
      
     <div class="card kl" style={{backgroundColor:'transparent', color:'#fff', paddingTop:'140px'}}> 
    <div class="card-body">
     <h3 class="mb-3  "> <span className='Nick'>Nick Bilton- </span></h3>
     <p class="card-text military mb-4" style={{fontSize:'18px'}}>Imagine how a medical robot, originally programmed to rid cancer, could conclude that the best way to obliterate cancer is to exterminate humans who are genetically prone to the disease.
     </p>

     
     <div>
     <NavLink to='https://twitter.com/nickbilton'  style={{color:'#fff'}}>
     <BsTwitter className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
 </NavLink>



 <NavLink to='https://www.linkedin.com/in/nickbilton/'  style={{color:'#fff'}}>
 <AiFillLinkedin className='twit ms-5' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
</NavLink>
     </div>
     
 </div>
     </div>
     </div>

    </div>  








     <div className='row mt-5 pt-5 kl'  style={{paddingTop:'110px'}}>
      
     <div className='col-lg-6 col-md-6 col-sm-12 my-4 order-last order-md-last'> 
      <div class="card  kl" style={{backgroundColor:'transparent', color:'#fff',paddingTop:'40px'}}> 
     <div class="card-body">
      <h3 class="mb-3 "> <span className='Nick'>Jaron Lanier- </span></h3>
      <p class="card-text military mb-4" style={{fontSize:'18px'}}> We’re still pretending that we’re inventing a brain when all we’ve come up with is a giant mash-up of real brains. We don’t yet understand how brains work, so we can’t build one.</p>

      <div>
      <NavLink to='http://www.jaronlanier.com/ '  style={{color:'#fff'}}>
      <TbNetwork className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
  </NavLink>


 
      </div>
      
  </div>
      </div>
      </div>




      <div className='col-lg-6 col-md-6 col-sm-12 mt-5 order-first order-md-last'>
          <img src={lan} alt='lan' className='p-3 lan'  style={{width:'100%',}}/>
      </div>

     </div>
     




     
     <div className='row mt-5 stt1'>  

     <div className='col-lg-5 col-md-6 col-sm-12 mt-5     '  >
         <img src={hec} alt='musk'  style={{width:'100%'}}/>
     </div> 

     
     <div className='col-lg-1 ' >
     </div>

     <div className='col-lg-6 col-md-6 col-sm-12 mt-5   kl1 ' >
      
     <div class="card kl" style={{backgroundColor:'transparent', color:'#fff', paddingTop:'90px'}}> 
    <div class="card-body">
     <h3 class="mb-3  "> <span className='Nick'> Stephen Hawking - </span></h3>
     <p class="  military mb-4" style={{fontSize:'18px'}}>The competitive advantage – economic, military, even artistic – of every advance in automation is so compelling that passing laws, or having customs, that forbid such things merely assures that someone else will get them first
     </p>

     <div>
     <NavLink to='https://en.wikipedia.org/wiki/Stephen_Hawking'  style={{color:'#fff'}}>
     <BsWikipedia className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
 </NavLink>
 
     </div>
     
 </div>
     </div>
     </div>

    </div>  


    <div className='row mt-5 pt-5 kl2'  style={{paddingTop:'110px'}}>
      
    <div className='col-lg-6 col-md-6 col-sm-12 my-4 order-last order-md-last'> 
     <div class="card  kl" style={{backgroundColor:'transparent', color:'#fff',paddingTop:'110px'}}> 
    <div class="card-body">
     <h3 class="mb-3. "><span className='Nick'> Vernor Vinge-</span> </h3>
     <p class="military mb-4" style={{fontSize:'18px'}}>The competitive advantage – economic, military, even artistic – of every advance in automation is so compelling that passing laws, or having customs, that forbid such things merely assures that someone else will get them first</p>


     <div>
     <NavLink to='https://en.wikipedia.org/wiki/Vernor_Vinge'  style={{color:'#fff'}}>
     <BsWikipedia className='twit' style={{border:'1px solid #fff', padding:'10px', fontSize:'45px', borderRadius:'8px'}}/>
 </NavLink>

     </div>
     
 </div>
     </div>
     </div>
 

     <div className='col-lg-6 col-md-6 col-sm-12 mt-5 order-first order-md-last'>
         <img src={abd} alt='lan' className='p-3 lan'  style={{width:'100%',}}/>
     </div>

    </div> 


      </div>

       
      </div>
      </div>

    
      <div className='mt-5' style={{display:Location.pathname=="/"?"none":""}}>
      <Footer/> 
      </div>


       

      
      </>
  )
}
