import React from 'react'
import Lottie from 'react-lottie-player'  
import lottieJson from '../../Comp/img/jai.json'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Sdk = () => {
  return (
<> 


<div>
  <Header/>
</div>
   <section>
    <div className='container'>
     <div className='row'>
      <div className='col-lg-8 col-sm-12 m-auto mt-5 pt-5'>

      <div style={{display:'flex', justifyContent:'center'}}>
      <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: '70%', height: 'auto' }}
    />
       
      </div> 
     </div>
     </div>
    </div>
   </section>



   <div className='mt-5'>
    <Footer/>
   </div>
</>
  )
}

export default Sdk