import React,{useState} from 'react' ;
import "./Modal.css";

const Modal = () => {

 // const [Subscribe, setSubscribe] = useState("");
  const [Check, setCheck] = useState("");
  
  const handledCheck = ()=>{
   
    
      setCheck("click");
  
   
    
   }
      const [bannerImage, setbannerImage] = useState();
      console.log(bannerImage);

  return (
    <>
     <div className='container'>
      <div className='row'>
       <div className='col-lg-12 col-sm-12'> 
 
       <div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Enter Your Name</label>
      <input type="text" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter Your Name' /> 
    </div> 
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Email address</label>
      <input type="email" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter Your Email' /> 
    </div> 

     
    
</div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Contact Number</label>
      <div class="input-group p-0  ">
  <span class="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent'}}>
   
  <select class="form-select   py-0 py-1" aria-label="Default select example" style={{border:'none', boxShadow:'none'}}>
  <option selected>+ 91</option>
  <option value="1">+ 71</option>
  <option value="2">+ 256</option>
  <option value="3">+ 93</option>
</select> 
  </span>
  <input type="number" class="form-control  "  aria-label="Username" aria-describedby="basic-addon1" placeholder='Enter Your Number' style={{ boxShadow:'none', border:'none', border:'1px solid #ced4da',borderLeft:'none',}}/> 
    </div>   
 
  </div> 


  <div className="row mt-4">
  {/*<p
    className="col-3 mt-1"  
    style={{
      fontSize: 16,
      fontWeight: 600,
      color: "#303030",
    }}
  >
    Banner Image
  </p>*/}

  <div> 
     <h5 className='pt-3'>Job preference</h5>

     <div className='py-3' style={{display:'flex',  }}>

     <div class="form-check">
     <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
     <label class="form-check-label" for="flexCheckDefault">
     Remote 
     </label>
   </div>

     <div class="form-check ms-4">
     <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
     <label class="form-check-label" for="flexCheckDefault">
     On-site
     </label>
   </div>

     </div>
  </div>
  <label className="filelabel2 col-2" 
  style={{backgroundColor:"#F5F5F5"}}
   onClick={()=>{}}>

     <span className="title2">
       upload Resume  {bannerImage}
     </span>
     <input className="FileUpload2" id="FileInput2"
      name="booking_attachment2" type="file" accept='.png,.jpg'
      onChange={(e)=>{setbannerImage(URL.createObjectURL(e.target.files[0]))}} />
  </label> 
</div>


<div className='mt-3' style={{display:'flex', justifyContent:"center"}}>
  
<button type='submit' style={{backgroundColor:'blue', color:'#fff',padding:'10px 12px', borderRadius:'10px', border:'none', width:'90%', transitionDuration: '500ms'}} data-bs-dismiss="modal" aria-label="Close" 
  onClick={handledCheck}>{Check=="click"?"Submited":"Submit"}</button>

</div>

       </div>
      </div>
      </div>
    </>
  )
}

export default Modal;