import React, { useState } from "react";
import "./Generate.css";
import { Configuration, OpenAIApi } from "openai";
import Header from "../Comp/Header/Header";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Footer from "../Comp/Footer/Footer";
import search from "../Comp/img/search.json";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import axios from "axios";
const MY_API_KEY = "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";

// Create a configuration instance
const configuration = new Configuration({
  apiKey: MY_API_KEY,
});

// Create an OpenAIApi instance
const openai = new OpenAIApi(configuration);

const Generate = () => {
  // chat box start

  // chat box end

  const [image, setimage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [promt, setPromt] = useState("");

  //function for image generator
  async function fetchRespo(x) {
    try {
      setIsLoading(true);
      const response = await axios.post(`https://api.armorai.io/api/chat`, {
        isimage: 1,
        search: x,
      });

      setimage(response.data.data[0].url);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  //function for
  const handleImageGeneratorOnEnter = (e) => {
    const { key, target } = e;
    const { value } = target;
    if (key === "Enter") {
      fetchRespo(value);
    } else {
      setPromt(value);
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>

      <div className="container-fluid Gen" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-12 m-auto">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12 ">
                <div>
                  <h1 className="Generator mt-5">
                    {" "}
                    <span className="yogesh">Image Generator </span>
                  </h1>

                  <div className="row mt-5">
                    <div className="col-lg-9 col-sm-12 mt-3">
                      <div>
                        <input
                          onKeyPress={handleImageGeneratorOnEnter}
                          className="Imagination"
                          onChange={(e) => setPromt(e.target.value)}
                          placeholder="Enter your Imagination here...."
                          style={{ width: "100%", color: "#fff" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mt-3">
                      <div className="boat1 ">
                        <button
                          className="Boat"
                          onClick={() => fetchRespo(promt)}
                        >
                          Generate Image
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="immgg mt-5">
                    {isLoading ? (
                      <>
                        <p>Loading</p>
                        <p>
                          please wait ...We are creating your Imagination into
                          reality
                        </p>
                      </>
                    ) : image ? (
                      // <div>
                      <img
                        src={image}
                        style={{
                          borderRadius: "7px",
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      //   <div style={{display:'flex', justifyContent:'right', marginTop:'-30px'}}>
                      //   <a href={image} download> Download Image</a>

                      // </div>
                      // </div>
                      <Player
                        autoplay
                        loop
                        src={search}
                        style={{ height: "100%", width: "250px" }}
                      ></Player>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Generate;
